<template>
  <form class="k-form signUp findIdComplete" id="signupForm" @submit.prevent="onSubmit()">
    <p class="etext mt25">입력하신 정보와 일치하는 아이디 정보입니다.</p>
    <fieldset>
      <p class="fem">{{userId}}</p>
      <p class="lnote">
        <span class="k-icon k-i-warning"></span>
        개인정보보호를 위해 아이디 뒷자리는 ***로 표시됩니다.</p>
      <input type="submit" value="로그인 하기" class="mid_btn orange login_btn mt25" style="cursor: pointer;">
    </fieldset>
  </form>
</template>

<script>
  export default {
    name: 'findIdComplete',
    props: {
      param: {
        type: Object,
        required: false
      }
    },
    methods: {
      onSubmit () {
        this.$router.push({ name: 'Login' })
      },
    },
    data () {
      return {
        userId: this.$props.param.userId.substring(0,this.$props.param.userId.length-2).padEnd(this.$props.param.userId.length, '*'),
      }
    },
  }

</script>
